import React from 'react';

const Condition = () => {
  return (
    <section id="condition">
        <div className="condition-content">
            <h2 className="title">КАКИЕ АВТОМОБИЛИ МЫ ВЫКУПАЕМ?</h2>

            <div className="condition-grid">
                <div className="grid-item">
                    <div className="icon">
                        <span className="fa-solid fa-car-tunnel" role="img"></span>
                    </div>
                    <p className="offer">Любой марки и модели</p>
                </div>
                <div className="grid-item">
                    <div className="icon">
                        <span className="fa-solid fa-car-on" role="img"></span>
                    </div>
                    <p className="offer">В любом состоянии</p>
                </div>
                <div className="grid-item">
                    <div className="icon">
                        <span className="fa-solid fa-car-burst" role="img"></span>
                    </div>
                    <p className="offer">После ДТП</p>
                </div>
                <div className="grid-item">
                    <div className="icon">
                        <span className="fa-solid fa-car-battery" role="img"></span>
                    </div>
                    <p className="offer">Не на ходу</p>
                </div>
                <div className="grid-item">
                    <div className="icon">
                        <span className="fa-solid fa-building-columns" role="img"></span>
                    </div>
                    <p className="offer">Автомобили в кредите</p>
                </div>
            </div>
        </div>
    </section>

  );
};

export default Condition;
