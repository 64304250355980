import React, { useState, useEffect } from 'react';

const Header = () => {
   const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerStyle = {
    borderBottomColor: scrolled ? '#fff' : 'transparent',
    backgroundColor: scrolled ? '#000' : 'transparent',
  };


  return (
    <header style={headerStyle}>
      <div className="header-container">
          <div className="logo">
              <img src="./img/logo.JPG" alt="Логотип Александр автовыкуп" height="60" />
          </div>
          <nav>
              <ul>
                  <li className="navigation-col">
                      <a href="tel:+375445343323" className="custom-icon-a1">+375 (44) 534-33-23</a>
                      <a href="tel:+375291617290" className="custom-icon-mts">+375 (29) 161-72-90</a>
                  </li>
                  <li><a href="tel: +375445343323" style={{color: 'rgb(197, 197, 197)'}} aria-label="Телефон"><span className="fa-solid fa-phone" role="img" aria-label="Телефон"></span></a></li>
                  <li><a href="viber://add?number=375445343323" style={{color: 'rgb(151, 71, 255)'}} aria-label="Viber"><span className="fa-brands fa-viber" role="img" aria-label="Viber"></span></a></li>
                  <li><a href="https://t.me/airprobel" style={{color: 'rgb(71, 145, 255)'}} aria-label="Telegram"><span className="fa-brands fa-telegram" role="img" aria-label="Telegram"></span></a></li>
            </ul>
          </nav>
        </div>
    </header>
  );
};

export default Header;
