import React from 'react';

import Header from './components/Header';
import Banner from './components/Banner';
import Information from './components/Information';
import Footer from './components/Footer';
import Condition from './components/Сonditionю';

function App() {
  return (
    <div>
      <Header />
      <Banner />
      <Information />
      <Condition />
      <Footer />
    </div>
  );
}

export default App;
