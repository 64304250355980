import React, { useState } from "react";

import axios from "axios";

import cars from "../data/cars";
import excludedValues from "../data/excludeCar";

const Form = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    brand: "",
    model: "",
    year: "",
    name: "",
    price: "",
    phone: "",
    comments: "",
  });

  const carData = cars;

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1959 }, (_, index) => 1960 + index);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.brand) {
      errors.brand = "Пожалуйста, выберите марку автомобиля.";
    }
  
    if (!formData.model) {
      errors.model = "Пожалуйста, выберите модель автомобиля.";
    }
  
    if (!formData.year) {
      errors.year = "Пожалуйста, выберите год автомобиля.";
    }
  
    if (!formData.name) {
      errors.name = "Пожалуйста, укажите ваше имя.";
    }
  
    if (!formData.price) {
      errors.price = "Пожалуйста, укажите желаемую цену.";
    }
  
    if (!formData.phone) {
      errors.phone = "Пожалуйста, укажите ваш номер телефона.";
    }
  
    return errors;
  };
  
  const resetForm = () => {
    setFormData({
      brand: "",
      model: "",
      year: "",
      name: "",
      price: "",
      phone: "",
      comments: "",
    });
  
    setFormErrors({});
  };

  const sendTelegramMessage = async (chatId, message, token) => {
    try {
      const response = await axios.post(
        `https://api.telegram.org/bot${token}/sendMessage`,
        {
          chat_id: chatId,
          text: message,
        }
      );

      if (response.data.ok) {
      } else {
      }
    } catch (error) {
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const chatId      = process.env.REACT_APP_TG_USER_TOKEN;
      const token       = process.env.REACT_APP_TG_BOT_TOKEN;
      
      const message = `Марка: ${formData.brand}\n Модель: ${formData.model}\nГод: ${formData.year}\nИмя: ${formData.name}\nЖелаемая цена: ${formData.price}\nТелефон: ${formData.phone}\nКомментарий:${formData.comments}`;

      await sendTelegramMessage(chatId, message, token);

      resetForm();
      setIsModalOpen(true);
    } else {
      setFormErrors(errors);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <h2 className="title">Оценить стоимость</h2>
      <form id="car-form" onSubmit={handleSubmit}>
        <label htmlFor="brandSelect">Марка</label>
        <select
          id="brandSelect"
          name="brand"
          value={formData.brand}
          onChange={handleInputChange}
        >
          <option value="">Выберите марку</option>
          {carData
          .filter((car) => !excludedValues.some(excludedValue => car.id.toUpperCase() === excludedValue.toUpperCase()))
          .map((car) => (
            <option key={car.id.toString()} value={car.id}>
              {car.name}
            </option>
          ))}
        </select>
        {formErrors.brand && <p className="form-error">{formErrors.brand}</p>}

        <label htmlFor="modelSelect">Модель</label>
        <select
          id="modelSelect"
          name="model"
          value={formData.model}
          onChange={handleInputChange}
          disabled={!formData.brand}
        >
          <option value="">Выберите модель</option>
          {formData.brand &&
            carData
              .find((car) => car.id === formData.brand)
              .models.map((model) => (
                <option key={model.id.toString()} value={model.id}>
                  {model.name}
                </option>
          ))}
          
        </select>
        {formErrors.model && <p className="form-error">{formErrors.model}</p>}

        <label htmlFor="yearSelect">Год</label>
        <select
          id="yearSelect"
          name="year"
          value={formData.year}
          onChange={handleInputChange}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        {formErrors.year && <p className="form-error">{formErrors.year}</p>}

        <label htmlFor="name">Ваше имя</label>
        <input
          id="name"
          name="name"
          type="text"
          value={formData.name}
          onChange={handleInputChange}
        />
        {formErrors.name && <p className="form-error">{formErrors.name}</p>}


        <label htmlFor="price">Желаемая цена</label>
        <div className="input-with-dollar">
          <input
            id="price"
            name="price"
            type="number"
            value={formData.price}
            onChange={handleInputChange}
          />
        </div>
        {formErrors.price && <p className="form-error">{formErrors.price}</p>}

        <label htmlFor="phone">Телефон</label>
        <input
          id="phone"
          name="phone"
          type="text"
          value={formData.phone}
          onChange={handleInputChange}
        />
        {formErrors.phone && <p className="form-error">{formErrors.phone}</p>}

        <label htmlFor="comments">Комментарий</label>
        <textarea
          id="comments"
          name="comments"
          value={formData.comments}
          onChange={handleInputChange}
        ></textarea>

        <button type="submit" className="button sendForm">
          Отправить
        </button>
      </form>

      {isModalOpen && (
        <div className="myModal modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img src="/img/ok.png" alt="" />
            <h2>Заявка отправлена!</h2>
            <p>Скоро наш менеджер свяжется с Вами!</p>
            <button className="closeBtn" onClick={closeModal}>
              Закрыть окно
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;
