import React, { useEffect } from 'react';

const Banner = () => {

    useEffect(() => {
        const banner = document.getElementById("banner");
        const information = document.getElementById("form");
    
        const handleWheel = (event) => {
          const deltaY = event.deltaY;
    
          if (deltaY > 0 && window.innerHeight + window.scrollY >= banner.offsetHeight) {
            information.scrollIntoView({ behavior: "smooth" });
            event.preventDefault();
          }
        };
    
        banner.addEventListener("wheel", handleWheel);
    
        return () => {
          banner.removeEventListener("wheel", handleWheel);
        };
    }, []);

    const handleScrollToForm = (event) => {
      event.preventDefault();
      const formSection = document.getElementById("form");
      formSection.scrollIntoView({ behavior: "smooth" });
    };
   
    return (
      <section id="banner">
          <div className="banner-content">
              <div className="banner-data">
                  <h1>СРОЧНЫЙ <span>ВЫКУП</span> АВТО</h1>
                  <h1><span>ЕЖЕДНЕВНО</span> 24/7</h1>
                  <h1>ВЫЕЗД ОЦЕНЩИКА <span>БЕСПЛАТНО</span></h1>
                  <div className="banner-social">
                      <a href="tel: +375445343323" style={{color: 'rgb(197, 197, 197)'}} aria-label="Телефон"><span className="fa-solid fa-phone" role="img" aria-label="Телефон"></span></a>
                      <a href="viber://add?number=375445343323" style={{color: 'rgb(151, 71, 255)'}} aria-label="Viber"><span className="fa-brands fa-viber" role="img" aria-label="Viber"></span></a>
                      <a href="https://t.me/airprobel" style={{color: 'rgb(71, 145, 255)'}} aria-label="Telegram"><span className="fa-brands fa-telegram" role="img" aria-label="Telegram"></span></a>
                  </div>
              </div>
              <div className="scroll-down">
                  <a href="#form" onClick={handleScrollToForm}>
                      <span className="fa-solid fa-angles-down scroll-down_i" aria-label="Прокрутка вниз"></span>
                  </a>
              </div>
          </div>
      </section>
  );
};

export default Banner;
