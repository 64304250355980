import React from 'react';

const Footer = () => {
  return (
    <footer>      
        <div itemType="http://schema.org/Organization">
            <h1 itemProp="name">True auto</h1>
            <p itemProp="description">Срочный выкуп автомобилей</p>
            <div className="contact-numbers">
                <a itemProp="telephone" href="tel:+375445343323" className="custom-icon-a1">+375 (44) 534-33-23</a>
                <a itemProp="telephone" href="tel:+375291617290" className="custom-icon-mts">+375 (29) 161-72-90</a>
            </div>
        </div>

        <ul className="social-icons">
            <li><a href="tel: +375445343323" style={{color: 'rgb(197, 197, 197)'}} aria-label="Телефон"><span className="fa-solid fa-phone" role="img" aria-label="Телефон"></span></a></li>
            <li><a href="viber://add?number=375445343323" style={{color: 'rgb(151, 71, 255)'}} aria-label="Viber"><span className="fa-brands fa-viber" role="img" aria-label="Viber"></span></a></li>
            <li><a href="https://t.me/airprobel" style={{color: 'rgb(71, 145, 255)'}} aria-label="Telegram"><span className="fa-brands fa-telegram" role="img" aria-label="Telegram"></span></a></li>
        </ul>  

        <p className="copyright">Все права защищены &copy; Автоподбор Минск - Александр</p>
    </footer>
  );
};

export default Footer;
