const excludedValues = [
    "Ac",
    "adler",
    "AION",
    "Aito",   
    "Aiways",   
    "Alpine",   
    "AMC",
    "AM_GENERAL", 
    "Apal",
    "Arcfox",
    "Ariel",
    "Aro",
    "Asia",
    "Auburn",
    "Aurus",
    "Austin",
    "AUSTIN_HEALEY",
    "AUTOBIANCHI",
    "AUTO_UNION",
    "Avatr",
    "Автокам",
    "BAIC",
    "Bajaj",
    "BALTIJAS_DZIPS",
    "BAOJUN",
    "BILENKIN",
    "BIO_AUTO",
    "Bitter",
    "Blaval",
    "BORGWARD",
    "BRILLIANCE",
    "BRISTOL",
    "BUFORI",
    "BYVIN",
    "CALLAWAY",
    "CARBODIES",
    "CATERHAM",
    "CHANA",
    "CHANGFENG",
    "CHANGHE",
    "CIIMO",
    "CIZETA",
    "COGGIOLA",
    "CORD",
    "CUPRA",
    "DADI",
    "DALLARA",
    "DECO_RIDES",
    "DELAGE",
    "DENZA",
    "DERWAYS",
    "DESOTO",
    "DE_TOMASO",
    "DKW",
    "DONGFENG",
    "DONINVEST",
    "DONKERVOORT",
    "DS",
    "DW_HOWER",
    "EAGLE",
    "EAGLE_CARS",
    "EVERUS",
    "EVOLUTE",
    "E_CAR",
    "E_MOBIL",
    "FACEL_VEGA",
    "FAW",
    "FORTHING",
    "FOTON",
    "FSO",
    "FUQI",
    "GAC",
    "GEO",
    "GOGGOMOBIL",
    "GONOW",
    "GORDON",
    "GP",
    "HAIMA",
    "HANOMAG",
    "HAWTAI",
    "HEINKEL",
    "HENNESSEY",
    "HINDUSTAN",
    "HIPHI",
    "HISPANO_SUIZA",
    "HOLDEN",
    "HONGQI",
    "HORCH",
    "HOZON",
    "HSV",
    "HUANGHAI",
    "HUDSON",
    "IM_MOTORS",
    "INNOCENTI",
    "INTERNATIONAL",
    "INVICTA",
    "IRAN_KHODRO",
    "ISDERA",
    "JAECOO",
    "JENSEN",
    "JETOUR",
    "JETTA",
    "JINBEI",
    "KAIYI",
    "KANONIR",
    "KG_MOBILITY",
    "KOENIGSEGG",
    "KOMBAT",
    "KTM",
    "LANDWIND",
    "LEAPMOTOR",
    "LIEBAO",
    "LIGIER",
    "LIVAN",
    "LIXIANG",
    "LOGEM",
    "LTI",
    "LUCID",
    "LUXGEN",
    "LYNK_CO",
    "MAHINDRA",
    "MARCOS",
    "MARLIN",
    "MARUSSIA",
    "MARUTI",
    "MERCURY",
    "MESSERSCHMITT",
    "METROCAB",
    "MICROCAR",
    "MINELLI",
    "MITSUOKA",
    "MOBILIZE",
    "MORGAN",
    "MORRIS",
    "NASH",
    "NIO",
    "NOBLE",
    "OLDSMOBILE",
    "OMODA",
    "ORA",
    "OSCA",
    "OSHAN",
    "OVERLAND",
    "PACKARD",
    "PAGANI",
    "PANOZ",
    "PERODUA",
    "PGO",
    "PIAGGIO",
    "PIERCE_ARROW",
    "POLESTAR",
    "PREMIER",
    "PUCH",
    "PUMA",
    "QOROS",
    "QVALE",
    "RAM",
    "RELIANT",
    "RENAISSANCE_CARS",
    "REZVANI",
    "RIMAC",
    "RINSPEED",
    "RISING_AUTO",
    "ROEWE",
    "RONART",
    "RUSSO_BALTIQUE",
    "SAIPA",
    "SALEEN",
    "SAMSUNG",
    "SEARS",
    "SERES",
    "SHANGHAI_MAPLE",
    "SHUANGHUAN",
    "SIMCA",
    "SKYWELL",
    "SKYWORTH",
    "SMZ",
    "SOUEAST",
    "SPECTRE",
    "SPYKER",
    "SWM",
    "TAGAZ",
    "TALBOT",
    "TANK",
    "TATA",
    "TAZZARI",
    "THAIRUNG",
    "THINK",
    "TIANMA",
    "TIANYE",
    "TOFAS",
    "TRABANT",
    "TRAMONTANA",
    "TRIUMPH",
    "TRUMPCHI",
    "TVR",
    "ULTIMA",
    "VAUXHALL",
    "VECTOR",
    "VENTURI",
    "VENUCIA",
    "VGV",
    "VORTEX",
    "VOYAH",
    "VUHL",
    "WANDERER",
    "WARTBURG",
    "WELTMEISTER",
    "WESTFIELD",
    "WEY",
    "WIESMANN",
    "WILLIS",
    "WULING",
    "W_MOTORS",
    "XINKAI",
    "XPENG",
    "YEMA",
    "YULON",
    "ZASTAVA",
    "ZENOS",
    "ZENVO",
    "ZIBAR",
    "ZX",
  ];

  
export default excludedValues;