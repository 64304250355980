import React from 'react';

import Form from './Form';

const Information = () => {
  return (
    <section id="form">
        <div className="form-content">
            <Form />
            <div className="we">
                <h2 className="title">Почему мы?</h2>
                <div className="we-grid">
                    <div className="grid-item">
                        <div className="icon">
                            <span className="fa-solid fa-shield-halved" role="img" aria-label="Гарантия Юридической чистоты"></span>
                        </div>
                        <p className="offer">Гарантия Юридической чистоты</p>
                    </div>
                    
                    <div className="grid-item">
                        <div className="icon">
                            <span className="fa-solid fa-piggy-bank" role="img" aria-label="Бесплатный выезд специалиста на место для осмотра и оценки вашего автомобиля"></span>
                        </div>
                        <p className="offer">Бесплатный выезд специалиста на место для осмотра и оценки вашего автомобиля</p>
                    </div>
            
                    <div className="grid-item">
                        <div className="icon">
                            <span className="fa-solid fa-money-bills" role="img" aria-label="Оплата за авто производится сразу-же после подписания документов"></span>
                        </div>
                        <p className="offer">Оплата за авто производится сразу-же после подписания документов</p>
                    </div>
            
                    <div className="grid-item">
                        <div className="icon">
                            <span className="fa-solid fa-building-shield" role="img" aria-label="Полное сопровождение сделки в ГАИ"></span>
                        </div>
                        <p className="offer">Полное сопровождение сделки в ГАИ</p>
                    </div>
            
                    <div className="grid-item">
                        <div className="icon">
                            <span className="fa-solid fa-1" role="img"></span>
                            <span className="fa-solid fa-0" role="img"></span>
                            <span className="fa-solid fa-plus" role="img"></span>
                        </div>
                        <p className="offer">Лет на рынке</p>
                    </div>
            
                    <div className="grid-item">
                        <div className="icon">
                            <span className="fa-solid fa-thumbs-up" role="img"></span>
                        </div>
                        <p className="offer">Делаем свою работу быстро, надежно и качественно</p>
                    </div>

                    <div className="grid-item">
                        <div className="icon">
                            <span className="fa-solid fa-calendar-days" role="img"></span>
                        </div>
                        <p className="offer">Работаем 24/7</p>
                    </div>
            
                    <div className="grid-item">
                        <div className="icon">
                            <i className="fa-solid fa-car"></i>
                            <i className="fa-solid fa-car-rear"></i>
                        </div>
                        <p className="offer">Более 4000 совершенных сделок</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Information;
